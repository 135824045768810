import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import AutoOrder from './autoOrder';
import Layout from '../components/layout';
import { GetAllLandingPagesQuery, GetLandingPageDataQuery } from '../../graphql-types';
import PageTypeContext from '../context/pageTypeContext';
import PageType from '../context/pageType';
import { filterByPageReference, initialComponents } from './components';

const GET_LANDING_PAGE_DATA = graphql`
  query GetLandingPageData {
    allNodeBodyBuilder(
      filter: { relationships: { field_landing_page_reference: { name: { ne: null } } } }
    ) {
      ...CompleteBodyBuilderItems
    }
    allNodeMasthead(
      filter: { relationships: { field_landing_page_reference: { name: { ne: null } } } }
    ) {
      ...CompleteMastheadsFields
    }
    allNodeTout(
      filter: { relationships: { field_landing_page_reference: { name: { ne: null } } } }
    ) {
      ...CompleteToutFields
    }
    allNode2UpGrid(
      filter: { relationships: { field_landing_page_reference: { name: { ne: null } } } }
    ) {
      ...Complete2upGridFields
    }
    allNode3UpInformative(
      filter: { relationships: { field_landing_page_reference: { name: { ne: null } } } }
    ) {
      ...Complete3UpInformativeFields
    }
    allNodeToutText(
      filter: { relationships: { field_landing_page_reference: { name: { ne: null } } } }
    ) {
      ...CompleteToutTextFields
    }
    allNodeContentAdvertise(
      filter: { relationships: { field_landing_page_reference: { name: { ne: null } } } }
    ) {
      ...CompleteContentAdvertiseFields
    }
    allNodeQuote(
      filter: { relationships: { field_landing_page_reference: { name: { ne: null } } } }
    ) {
      ...CompleteQuoteFields
    }
    allNodeToutSimple(
      filter: { relationships: { field_landing_page_reference: { name: { ne: null } } } }
    ) {
      ...CompleteToutSimpleFields
    }
    allNodeSimpleMedia(
      filter: { relationships: { field_landing_page_reference: { name: { ne: null } } } }
    ) {
      ...CompleteSimpleMediaFields
    }
    allNodeMediaGrid(
      filter: { relationships: { field_landing_page_reference: { name: { ne: null } } } }
    ) {
      ...CompleteMediaGridFields
    }
    allNodeCarousel(
      filter: { relationships: { field_landing_page_reference: { name: { ne: null } } } }
    ) {
      ...CompleteCarouselFields
    }
    allNodeArticleTout(
      filter: { relationships: { field_landing_page_reference: { name: { ne: null } } } }
    ) {
      ...CompleteArticleToutFields
    }
    allNodeBodyContent(
      filter: { relationships: { field_landing_page_reference: { name: { ne: null } } } }
    ) {
      ...CompleteBodyContentFields
    }
    allNodeDownloadableContent(
      filter: { relationships: { field_landing_page_reference: { name: { ne: null } } } }
    ) {
      ...CompleteDownloadableContentFields
    }
    allNodeQuickLi(
      filter: { relationships: { field_landing_page_reference: { name: { ne: null } } } }
    ) {
      ...CompleteQuickLinksFields
    }
    allNodeNewsGrid(
      filter: { relationships: { field_landing_page_reference: { name: { ne: null } } } }
    ) {
      ...CompleteNewsGridFields
    }
    allNodeArticlesGrid(
      filter: { relationships: { field_landing_page_reference: { name: { ne: null } } } }
    ) {
      ...CompleteArticlesGridFields
    }
    allNodeTout5050(
      filter: { relationships: { field_landing_page_reference: { name: { ne: null } } } }
    ) {
      ...CompleteTout5050Fields
    }
    allNodeMasthead2022(
      filter: { relationships: { field_landing_page_reference: { name: { ne: null } } } }
    ) {
      ...CompleteMasthead2022Fields
    }
  }
`;

interface Props {
  pageContext: {
    landingPageData: GetAllLandingPagesQuery['allNodeLandingPage']['nodes'][0];
  };
}

const LandingPageBuilder: React.FC<Props> = ({ pageContext }) => {
  const data = useStaticQuery<GetLandingPageDataQuery>(GET_LANDING_PAGE_DATA);
  const [filteredData, setFilteredData] = useState<GetLandingPageDataQuery>(initialComponents);

  const { landingPageData } = pageContext;
  const landingPageName = landingPageData.relationships?.field_landing_page_id?.name;

  useEffect(() => {
    if (landingPageName) {
      setFilteredData(
        filterByPageReference(landingPageName, filteredData, data, 'field_landing_page_reference')
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landingPageName, data]);

  if (!landingPageName) {
    return null;
  }

  return (
    <Layout
      seo={{
        'title': landingPageData?.title ?? '',
        'author': 'Deutsch LA',
        'keywords': 'read, deutsch',
      }}
    >
      <PageTypeContext.Provider value={{ pageType: PageType.Landing }}>
        <AutoOrder data={filteredData} id={landingPageName.replace('/', '-')} />
      </PageTypeContext.Provider>
    </Layout>
  );
};

export default LandingPageBuilder;
